<template>
  <div class="logoff">
    <topbar title="注销贪吃商城CPS佣金服务" background="#e7141a" />
    <div class="icon">
      <van-icon name="warning"></van-icon>
    </div>
    <div class="title">注销贪吃商城CPS佣金服务</div>
    <div class="subtitle">
      如注销此服务，您的留存信息将被处理（删除或匿名化）
      <span v-if="setting.reRegisterLimitDays"
        >且{{ setting.reRegisterLimitDays || 0 }} 天内无法重新注册，</span
      >
      包括但不仅限于：
    </div>
    <div class="info">
      <div class="item">昵称、头像等个人信息</div>
      <div class="item">已经认证的身份信息（如：签约信息）</div>
      <div class="item">成功引流人数及CPS佣金</div>
      <div class="item">以及在使用的各类产品/服务留存的其他信息</div>
    </div>
    <div class="action">
      <div class="btn cancel" @click="back">放弃注销</div>
      <div :class="`btn ${isDisabled && 'disabled'}`" @click="handleLogoff">
        继续注销
        <template v-if="isDisabled">
          (
          <van-count-down :time="11 * 1000" use-slot @change="onTimeChange" @finish="onTimeFinish">
            <div class="seconds">{{ timeData.seconds }}</div>
          </van-count-down>
          )
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import { CountDown, Dialog } from 'vant'
export default {
  data() {
    return {
      setting: {},
      isDisabled: true,
      timeData: {}
    }
  },
  mounted() {
    this.getSetting()
  },
  methods: {
    getSetting() {
      this.$http.get('/boom-mix-biz-service/app/distribution/generalSetting').then(res => {
        this.setting = res
      })
    },
    onTimeChange(e) {
      this.timeData = e
    },
    onTimeFinish() {
      this.isDisabled = false
    },
    back() {
      this.$router.back()
    },
    handleLogoff() {
      if (this.isDisabled) {
        return
      }
      Dialog.confirm({
        title: '确定要注销吗？',
        beforeClose: (action, done) => {
          if (action == 'confirm') {
            this.$http
              .post('/boom-center-user-service/app/distributionMember/deregister')
              .then(() => {
                Dialog({
                  title: '注销成功'
                }).then(res => {
                  this.$store.dispatch('user/logout')
                  this.$router.replace('/')
                })
              })
              .catch(err => {
                Dialog({
                  title: err.msg || '注销失败'
                })
              })
              .finally(() => {
                done()
              })
          } else {
            done()
          }
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.logoff {
  padding: 15px;
  min-height: 100vh;
  background-color: #f5f5f5;
  box-sizing: border-box;
}

.icon {
  font-size: 65px;
  color: red;
  text-align: center;
  width: 100%;
  margin-top: 30px;
}

.title {
  font-size: 19px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
  color: #333;
}

.subtitle {
  word-break: break-all;
  font-size: 14px;
  color: #777;
  padding: 0 30px;
}

.info {
  margin-top: 15px;
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;
  padding-bottom: 1px;

  .item {
    margin-bottom: 15px;
    font-size: 14px;
    color: #333;
  }
}

.action {
  display: flex;
  justify-content: center;
  margin-top: 30px;

  .btn {
    padding: 10px 30px;
    background-color: #333;
    border-radius: 30px;
    color: #fff;
    font-size: 14px;
    height: fit-content;

    &.cancel {
      background-color: #e7141a;
    }

    &.disabled {
      background-color: #999;
    }

    &:first-child {
      margin-right: 30px;
    }

    &:active {
      filter: brightness(90%);
    }
  }
}

.seconds {
  color: #fff;
  display: inline;
}

.van-count-down {
  width: fit-content;
  display: inline-block;
}
</style>